import { createFileRoute, Link } from '@tanstack/react-router'
import NavBar from '../components/NavBar'
import hero from '../assets/images/hero2.jpg';
import ActionButton from '../components/ActionButton';
import Footer from '../components/Footer';

export const Route = createFileRoute('/about')({
  component: About,
})

function About() {
  return (
    <div className='bg-primary-light text-xl'>
      <NavBar />
      <div style={{ backgroundImage: `url(${hero})` }} className='bg-cover bg-center mt-20 w-screen relative h-96'>
        <div className='flex justify-center items-center w-full h-full bg-primary-dark bg-opacity-80 text-primary-light'>
          <h2 className='text-7xl font-bold'>About Us</h2>
        </div>
      </div>
      <div className='bg-primary-light pt-24 pb-24'>
        <h2 className='md:ml-64 md:mr-64 ml-6 mr-6 text-primary-dark text-6xl font-bold border-b-2 border-primary'>Who We Are</h2>
        <p className='p-6 md:ml-64 md:mr-64'>
          Welcome to GreyStone, your premier source for high-quality landscape rock and materials. Established with a 
          passion for enhancing outdoor spaces, we specialize in providing a diverse selection of decorative rocks, 
          gravel, sand, bark, landscape boulders, and other essential landscaping materials to meet the needs of 
          homeowners, landscapers, and contractors alike.
        </p>          
        <p className='p-6 pt-0 md:ml-64 md:mr-64'>
          We offer a wide variety of colors, sizes, and textures of landscape rock. Whether you’re looking to create a 
          stunning rock garden, construct a durable pathway, or add visual interest to your flower beds, we have the 
          perfect solution for you.
        </p>
        <p className='p-6 pt-0 pb-24 md:ml-64 md:mr-64'>
          At GreyStone, we pride ourselves on exceptional customer service and expertise. Our knowledgeable team is here 
          to guide you in selecting the right materials for your project, ensuring that you achieve the look and 
          functionality you desire. We understand that your outdoor space is a reflection of your style and vision, which 
          is why we strive to provide products that not only meet but exceed your expectations. With our dedication to quality.
        </p>

        <h2 className='md:ml-64 md:mr-64 ml-6 mr-6 text-primary-dark text-6xl font-bold border-b-2 border-primary'>Why Choose Us</h2>
        <div className=''>
          <p className='p-6 md:ml-64 md:mr-64'>
            <strong>Wide Selection of Products:</strong> We offer a comprehensive range of landscaping materials, including landscape bark, decorative rocks, topsoil, sand, gravel, 
            and ice melt salt. Our diverse inventory ensures that you can find everything you need in one place, saving you time and effort.
          </p>
          <p className='p-6 pt-0 md:ml-64 md:mr-64'>
            <strong>Competitive Pricing:</strong> We are committed to providing high-quality products at affordable prices. By sourcing our materials directly from trusted suppliers, 
            we keep our costs low and pass those savings on to you. Our goal is to make quality landscaping accessible without sacrificing quality.
          </p>
          <p className='p-6 pt-0 md:ml-64 md:mr-64'>
            <strong>Exceptional Customer Service:</strong> Our knowledgeable and friendly team is dedicated to ensuring you have the best experience possible. We take the time to 
            understand your needs and provide personalized recommendations, making your landscaping project a success.
          </p>
          <p className='p-6 pt-0 md:ml-64 md:mr-64'>
            <strong>Reliable Delivery Services:</strong> We pride ourselves on prompt and reliable delivery. Our efficient scheduling and experienced drivers ensure your products 
            arrive on time, so you can get started on your project without delay.
          </p>
          <p className='p-6 pt-0 md:ml-64 md:mr-64'>
            <strong>Community Commitment:</strong> As a locally owned and operated business, we are dedicated to supporting our community. We understand the unique needs of our 
            customers and strive to provide the best solutions for your needs.
          </p>
        </div>
      </div>
      <div className='relative bg-primary-light flex flex-col items-center justify-center md:mt-64'>
          <div className="md:absolute -top-72 bg-white text-center z-40 p-6 pt-12 pb-12 w-full md:mt-24 md:w-5/12 md:rounded-sm md:p-12">
              <h2 className='text-4xl md:text-6xl pb-3 text-primary-dark'>
                Explore our landscape rock and materials!
              </h2>
              <Link to="/products" className='flex justify-center pt-12'>
                <ActionButton text="Shop Some Rocks" />
              </Link>
          </div>
      </div>
     
      <div className="bg-primary-dark md:pt-64">
          <Footer />
      </div>
    </div>
  )
}
