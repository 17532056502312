import React from 'react';

interface ProductCardProps {
    productName: string;
    productDescription: string;
    productImage: string;
    productPrice: string;
    isLastProduct: boolean;
    index: number;
}

const ProductCard: React.FC<ProductCardProps> = ({ productName, productDescription, productPrice, productImage, isLastProduct, index }) => {
    return (
        <div className={`${index%2 ? `bg-primary-dark text-primary-light` : `bg-primary-light`} md:bg-primary-light md:text-primary-dark flex flex-col-reverse justify-between items-center pt-6 pb-16 pl-6 pr-6 md:pb-16 md:mt-16 md:ml-16 md:mr-16 md:flex-row ${!isLastProduct ? 'md:border-b-2 border-primary-dark' : ''}`}>
            <div className='mr-6 ml-6 md:w-1/2 md:mr-0 md:ml-0'>
                <h2 className="text-3xl font-bold">{productName}</h2>
                <p className="text-2xl md:pl-6">{productDescription}</p>
                {
                    productPrice ?
                    <p className="text-2xl pt-6"><strong>Price:</strong> {productPrice}</p> :
                    null
                }
            </div>
            <div className="pb-6 w-full md:w-64 md:h-64 md:pb-0">
                <img src={productImage} alt={productName} className="object-cover w-full h-full"/>
            </div>
        </div>
    );
}

export default ProductCard;