import React from 'react';
import NavBar from './NavBar';
import '../App.css';
import hero from '../assets/images/hero2.jpg';
import gravel from '../assets/images/gravel.jpg';
import logo from '../assets/logos/yellow-white.png';
import pillars from '../assets/images/pillars.jpg';
import ActionButton from './ActionButton';
import ReviewCarousel from './ReviewCarousel';
import Footer from './Footer';
import { FaTruck, FaStoreAlt, FaCheck } from "react-icons/fa";
import { MdAttachMoney } from "react-icons/md";
import { IoPerson } from "react-icons/io5";
import { Link } from '@tanstack/react-router';
import { ContentfulReview, ContentfulProductHighlight } from '../types/contentfulTypes'

interface HomePageProps {
    reviews: ContentfulReview[];
    productHighlights: ContentfulProductHighlight[];
}

const HomePage: React.FC<HomePageProps> = ({ reviews, productHighlights }) => {
    return (
        <div className="relative font-leagueSpartan bg-primary-light w-full h-full">
            <NavBar />
            <div style={{ backgroundImage: `url(${hero})` }} className='bg-cover bg-center mt-20 w-screen relative h-3/5 md:h-4/5'> 
                <div className='absolute top-0 left-0 w-full h-full bg-primary-dark bg-opacity-80 text-primary-light'>
                    <div className="ml-4 mr-4 mt-24 md:ml-32">
                        <h2 className="text-4xl md:text-6xl">Premium Landscaping <br /> Materials</h2>
                        <p className="text-lg md:text-3xl pt-8 md:pr-24 xl:w-1/2">
                        Welcome to GreyStone, your premier source for high-quality landscape rock and materials.
                        </p>
                        <p className="text-lg md:text-3xl pt-8 md:pr-24 xl:w-1/2">
                        We offer a comprehensive range of landscaping materials, including landscape bark, decorative 
                        rocks, topsoil, sand, gravel, and ice melt salt.
                        </p>
                    </div>
                </div>
                <div className='absolute hidden -bottom-24 md:inline md:-bottom-48 right-0 w-full -skew-y-3 bg-primary-light h-1/3'>
                </div>                
            </div>
            <div className='relative bg-primary-light flex flex-col items-center justify-center'>            
                <div className="bg-white text-center z-40 p-6 pt-12 pb-12 md:-mt-64 md:w-5/12 md:rounded-sm md:p-12">
                    <h2 className='text-4xl md:text-6xl pb-12 text-primary-dark'> Get your material ordered today.</h2>
                    <a href={`tel:385.400.4719`}>
                        <ActionButton text="Order Now" />
                    </a>
                </div>
                <div className='mt-32 w-full'>
                    <div className='flex flex-wrap items-center justify-center w-full text-3xl pl-2 pr-2 pb-52 lg:text-6xl lg:pl-16 lg:pr-16'>
                        {
                            productHighlights.map((productHighlight, index) => (
                                <a className='flex items-center justify-center w-1/2 h-44 2xl:w-1/3 2xl:h-96 p-1' href="#">
                                    <div className='relative bg-cover bg-no-repeat w-full h-full' style={{ backgroundImage: `url(${productHighlight.productImage})`}}>
                                        <div className='absolute bg-primary-dark bg-opacity-50 w-full h-full hover:bg-primary hover:bg-opacity-50' />
                                        <h2 className='absolute bottom-0 font-bold text-white ml-4 mr-4'>{productHighlight.productName.toUpperCase()}</h2>
                                    </div>
                                </a>
                            ))
                        }                        
                    </div>
                    <div className='bg-primary-dark text-primary-light flex flex-col pl-6 pr-6 md:pl-36 md:pr-36'>
                    <div className='md:flex items-center justify-between pt-16 pb-16 border-b-2 border-primary'>
                        <div className='flex items-center w-full md:w-1/2'>
                            <div className="pr-8 md:pr-0 md:w-1/2">
                                <FaTruck className="text-6xl md:text-8xl"/>
                            </div>
                            <h2 className='text-5xl font-medium md:w-1/2'>DELIVERY</h2>
                        </div>
                        <p className='pt-6 text-xl md:w-1/2 md:pl-12 md:text-2xl'>
                        We pride ourselves on prompt and reliable delivery. Our efficient scheduling and experienced drivers ensure 
                        your products arrive on time, so you can get started on your project without delay.
                        </p>
                    </div>
                    <div className='md:flex items-center justify-between pt-16 pb-16 border-b-2 border-primary'>
                        <div className='flex items-center w-full md:w-1/2'>
                            <div className="pr-8 md:pr-0 md:w-1/2">
                                <MdAttachMoney className="text-6xl md:text-8xl"/>
                            </div>
                            <h2 className='text-5xl font-medium md:w-1/2'>COMPETITIVE PRICING</h2>
                        </div>
                        <p className='pt-6 text-xl md:w-1/2 md:pl-12 md:text-2xl'>
                        We are committed to providing high-quality products at affordable prices. By sourcing our 
                        materials directly from trusted suppliers, we keep our costs low and pass those savings on 
                        to you. Our goal is to make quality landscaping accessible without sacrificing quality.                    </p>
                    </div>
                    <div className='md:flex items-center justify-between pt-16 pb-16 border-b-2 border-primary'>
                        <div className='flex items-center w-full md:w-1/2'>
                            <div className="pr-8 md:pr-0 md:w-1/2">
                                <IoPerson className="text-6xl md:text-8xl"/>
                            </div>
                            <h2 className='text-5xl font-medium md:w-1/2'>CUSTOMER SERVICE</h2>
                        </div>
                        <p className='pt-6 text-xl md:w-1/2 md:pl-12 md:text-2xl'>
                        Our knowledgeable and friendly team is dedicated to ensuring you have the best experience 
                        possible. We take the time to understand your needs and provide personalized recommendations, 
                        making your landscaping project a success.
                        </p>
                    </div>
                    <div className='md:flex items-center justify-between pt-16 pb-52'>
                        <div className='flex items-center w-full md:w-1/2'>
                            <div className="pr-8 md:pr-0 md:w-1/2">
                                <FaCheck className="text-6xl md:text-8xl"/>
                            </div>
                            <h2 className='text-5xl font-medium md:w-1/2'>PRODUCT SELECTION</h2>
                        </div>
                        <p className='pt-6 text-xl md:w-1/2 md:pl-12 md:text-2xl'>
                        We offer a comprehensive range of landscaping materials, including landscape bark, 
                        decorative rocks, topsoil, sand, gravel, and ice melt salt. Our diverse inventory ensures 
                        that you can find everything you need in one place, saving you time and effort.
                        </p>
                    </div>
                </div>
                    {/* <div className='flex flex-col-reverse md:flex-row'>
                        <img src={pillars} alt="Pillars" className='object-cover order-last md:order-first md:w-1/2'/>
                        <div className='bg-primary-light text-primary-dark p-6 pt-20 pb-20 order-first md:order-last md:w-1/2 md:p-36'>
                            <h2 className='text-5xl pb-4 font-medium md:pb-12'>Best Landscaping Materials Around</h2>
                            <p className='text-2xl'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor 
                                incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation 
                                ullamco laboris nisi ut aliquip ex ea commodo consequat.
                            </p>
                            <Link to="/products" className='flex justify-center pt-12'>
                                <ActionButton text="Check Out Our Products" />
                            </Link>
                        </div>
                    </div> */}
                    <div className='flex flex-col-reverse md:flex-row'>
                        <div className='bg-primary-light text-primary-dark p-6 pt-20 pb-20 md:w-1/2 md:p-36'>
                            <h2 className='text-5xl pb-4 font-medium md:pb-12'>Materials Calculator</h2>
                            <p className='text-2xl'>Try our landscaping materials calculator to get an estimate on how much material 
                                you will need for your project and a cost estimate free of charge.
                            </p>
                            <Link to="/pricing" className='flex justify-center pt-12'>
                                <ActionButton text="Try Our Pricing Calculator" />
                            </Link>
                        </div>
                        <img src={hero} alt="Hero" className='object-cover md:w-1/2'/>
                    </div>
                </div>
                {/* <div className='absolute hidden -bottom-96 right-0 w-full skew-y-3 bg-primary-dark h-1/6 md:inline'>
                </div>  */}
                <ReviewCarousel reviews={reviews} />
            </div>
            <Footer />
        </div>
    );
}

export default HomePage;